import React from "react";

const History = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g >
      <path
        d="M13.3992 14.4004L9.69922 11.7004V5.40039H10.8992V11.1004L14.0992 13.4004L13.3992 14.4004Z"
        fill="currentColor"
      />
      <path
        d="M18.3004 13.1996C17.1004 16.3996 14.1004 18.4996 10.7004 18.4996C6.30039 18.4996 2.60039 14.8996 2.60039 10.3996C2.60039 5.89961 6.20039 2.29961 10.7004 2.29961C13.2004 2.29961 15.6004 3.49961 17.1004 5.49961L15.7004 6.59961L19.2004 7.49961L19.3004 3.89961L18.1004 4.79961C16.3004 2.49961 13.6004 1.09961 10.7004 1.09961C5.60039 1.09961 1.40039 5.29961 1.40039 10.3996C1.40039 15.4996 5.60039 19.6996 10.7004 19.6996C14.6004 19.6996 18.1004 17.1996 19.4004 13.5996L18.3004 13.1996Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default History;
