import React from "react";

const Headset = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1342 39.2969C12.2415 39.3076 11.3555 39.142 10.527 38.8097C9.69846 38.4774 8.94365 37.9848 8.30584 37.3602C7.66803 36.7356 7.15976 35.9912 6.81016 35.1698C6.46056 34.3484 6.27651 33.4661 6.26855 32.5734V29.9531C6.27391 28.1716 6.98399 26.4646 8.24372 25.2048C9.50345 23.9451 11.2105 23.235 12.992 23.2297H13.1342C13.4143 23.2297 13.683 23.341 13.8811 23.539C14.0791 23.7371 14.1904 24.0058 14.1904 24.2859V38.2406C14.1904 38.5208 14.0791 38.7894 13.8811 38.9875C13.683 39.1856 13.4143 39.2969 13.1342 39.2969ZM12.0982 25.4234C11.0477 25.63 10.1012 26.1943 9.41974 27.0201C8.73832 27.846 8.3641 28.8824 8.36074 29.9531V32.5734C8.37342 33.6371 8.75188 34.664 9.43252 35.4815C10.1132 36.299 11.0545 36.8573 12.0982 37.0625V25.4234Z"
      fill="currentColor"
    />
    <path
      d="M53.0687 39.2969H52.9469C52.6667 39.2969 52.3981 39.1856 52.2 38.9875C52.0019 38.7894 51.8906 38.5208 51.8906 38.2406V24.2859C51.8906 24.0058 52.0019 23.7371 52.2 23.539C52.3981 23.341 52.6667 23.2297 52.9469 23.2297C53.8395 23.219 54.7255 23.3845 55.5541 23.7168C56.3826 24.0492 57.1374 24.5418 57.7752 25.1664C58.413 25.791 58.9213 26.5353 59.2709 27.3567C59.6205 28.1781 59.8045 29.0604 59.8125 29.9531V32.5734C59.8071 34.3585 59.0942 36.0685 57.8301 37.3289C56.566 38.5892 54.8538 39.2969 53.0687 39.2969ZM53.9828 25.4234V37.0625C55.0314 36.8553 55.9754 36.2903 56.6535 35.464C57.3315 34.6378 57.7014 33.6016 57.7 32.5328V29.9531C57.7014 28.8843 57.3315 27.8481 56.6535 27.0219C55.9754 26.1956 55.0314 25.6306 53.9828 25.4234Z"
      fill="currentColor"
    />
    <path
      d="M34.4826 41.7344H31.5576C30.4358 41.7344 29.5264 42.6438 29.5264 43.7656V44.3547C29.5264 45.4765 30.4358 46.3859 31.5576 46.3859H34.4826C35.6044 46.3859 36.5139 45.4765 36.5139 44.3547V43.7656C36.5139 42.6438 35.6044 41.7344 34.4826 41.7344Z"
      fill="currentColor"
    />
    <path
      d="M48.2749 45.1875H33.7311C33.5739 45.2324 33.4085 45.2402 33.2477 45.2103C33.087 45.1804 32.9354 45.1136 32.8049 45.0151C32.6744 44.9167 32.5685 44.7893 32.4956 44.6429C32.4227 44.4966 32.3848 44.3354 32.3848 44.1719C32.3848 44.0084 32.4227 43.8471 32.4956 43.7008C32.5685 43.5545 32.6744 43.4271 32.8049 43.3286C32.9354 43.2302 33.087 43.1634 33.2477 43.1335C33.4085 43.1035 33.5739 43.1114 33.7311 43.1563H48.2749C51.8296 43.1563 51.8905 38.5859 51.8905 38.3828C51.8905 38.245 51.9179 38.1087 51.9713 37.9816C52.0246 37.8546 52.1027 37.7395 52.2011 37.643C52.2994 37.5466 52.416 37.4707 52.5441 37.4198C52.6721 37.369 52.809 37.3442 52.9468 37.3469C53.2215 37.3469 53.485 37.456 53.6793 37.6503C53.8736 37.8446 53.9827 38.1081 53.9827 38.3828C53.9827 40.6172 52.8046 45.1875 48.2749 45.1875Z"
      fill="currentColor"
    />
    <path
      d="M32.9998 61.9453C31.3332 61.9399 29.7368 61.2741 28.5603 60.0938C27.3838 58.9135 26.7232 57.3149 26.7232 55.6484V49.9609C26.7216 49.8001 26.7575 49.6411 26.828 49.4966C26.8985 49.352 27.0017 49.2259 27.1294 49.1281C27.2647 49.0362 27.4189 48.9758 27.5805 48.9512C27.7422 48.9266 27.9073 48.9385 28.0638 48.9859C29.5458 49.5033 31.1051 49.7644 32.6748 49.7578H33.3451C34.9146 49.7624 36.4736 49.5014 37.956 48.9859C38.1092 48.9387 38.2711 48.927 38.4294 48.9516C38.5878 48.9763 38.7385 49.0366 38.8701 49.1281C39.0004 49.2248 39.1066 49.3503 39.1806 49.4947C39.2546 49.6391 39.2943 49.7987 39.2966 49.9609V55.6484C39.2966 57.3184 38.6332 58.9201 37.4523 60.101C36.2714 61.2819 34.6698 61.9453 32.9998 61.9453ZM28.7748 51.2812V55.6078C28.7344 56.1913 28.8145 56.777 29.0101 57.3283C29.2057 57.8795 29.5126 58.3847 29.9117 58.8124C30.3108 59.24 30.7936 59.581 31.3301 59.8141C31.8666 60.0473 32.4453 60.1676 33.0302 60.1676C33.6152 60.1676 34.1939 60.0473 34.7304 59.8141C35.2668 59.581 35.7496 59.24 36.1488 58.8124C36.5479 58.3847 36.8547 57.8795 37.0503 57.3283C37.2459 56.777 37.326 56.1913 37.2857 55.6078V51.2812C36.0013 51.5878 34.6858 51.7445 33.3654 51.7484H32.6951C31.3746 51.7458 30.0589 51.589 28.7748 51.2812Z"
      fill="currentColor"
    />
    <path
      d="M33.528 51.7891H32.5327C28.2229 51.7891 24.0897 50.077 21.0422 47.0295C17.9948 43.9821 16.2827 39.8488 16.2827 35.5391V26.5C16.2827 22.1902 17.9948 18.057 21.0422 15.0095C24.0897 11.962 28.2229 10.25 32.5327 10.25H33.528C37.8378 10.25 41.9711 11.962 45.0185 15.0095C48.066 18.057 49.778 22.1902 49.778 26.5V35.5187C49.7807 37.6544 49.3624 39.7697 48.5469 41.7436C47.7315 43.7175 46.5349 45.5113 45.0257 47.0224C43.5165 48.5335 41.7242 49.7323 39.7513 50.5502C37.7785 51.3681 35.6637 51.7891 33.528 51.7891ZM32.5327 12.2609C28.7617 12.2609 25.1451 13.759 22.4785 16.4255C19.812 19.092 18.314 22.7086 18.314 26.4797V35.4984C18.314 39.2695 19.812 42.8861 22.4785 45.5526C25.1451 48.2191 28.7617 49.7172 32.5327 49.7172H33.528C37.2991 49.7172 40.9157 48.2191 43.5822 45.5526C46.2487 42.8861 47.7468 39.2695 47.7468 35.4984V26.5C47.7468 22.729 46.2487 19.1124 43.5822 16.4458C40.9157 13.7793 37.2991 12.2812 33.528 12.2812L32.5327 12.2609Z"
      fill="currentColor"
    />
    <path
      d="M52.9265 27.1703C52.6571 27.1703 52.3988 27.0633 52.2083 26.8728C52.0178 26.6824 51.9108 26.424 51.9108 26.1547C51.9108 21.3439 49.9998 16.7302 46.598 13.3284C43.1963 9.9267 38.5826 8.01562 33.7718 8.01562H32.2687C27.4614 8.02101 22.8529 9.93446 19.4555 13.3356C16.0582 16.7368 14.1499 21.3474 14.1499 26.1547C14.1447 26.4224 14.036 26.6777 13.8467 26.8671C13.6573 27.0564 13.402 27.1651 13.1343 27.1703C12.8649 27.1703 12.6066 27.0633 12.4161 26.8728C12.2257 26.6824 12.1187 26.424 12.1187 26.1547C12.1186 20.8087 14.2409 15.6814 18.0192 11.8993C21.7975 8.11722 26.9227 5.98976 32.2687 5.98438H33.7718C39.1196 5.98975 44.2469 8.11656 48.0284 11.8981C51.8099 15.6796 53.9367 20.8068 53.9421 26.1547C53.9421 26.424 53.8351 26.6824 53.6446 26.8728C53.4542 27.0633 53.1958 27.1703 52.9265 27.1703Z"
      fill="currentColor"
    />
  </svg>
  
);

export default Headset;
