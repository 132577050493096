/**
 * CkIcon component
 *
 * The CkIcon component is an extendable wrapper around the Ant Design Icon component.
 * It takes an additional `name` prop, which should match one of the exported icon names in `CkAvailableIcons`.
 * The SVG for the icon is dynamically imported based on this name.
 * If the import fails, the component returns null and does not render.
 *
 * All other props are the same as for Ant's Icon component.
 *
 * @component
 * @example
 * // This is how you can use the CkIcon with a specific icon name and additional props
 * <CkIcon name="arrow-right" spin/>
 *
 * @extends {CustomIconComponentProps}
 *
 * @property {string} name - Required: Icon name which should be one of the `CkAvailableIcons`
 * @property {React.SVGAttributes<SVGElement>} rest - Optional: Any other props will be passed to the Ant Design Icon component
 */
import React, { FC } from "react";
import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

/**
 * Type CkIconNames
 *
 * `CkIconNames` is a union type that consists of string literals which represent the names of the icons that can be used.
 * These string literals can be passed as the `name` prop in the `CkIconProps` interface.
 *
 * The icon represented by the `name` will be rendered on the screen by the component which accepts `CkIconProps` as its props.
 */
export type CkIconNames =
  | "24hours"
  | "add-image"
  | "added-checkmark"
  | "appo-tracking"
  | "arrow-bar-left"
  | "arrow-bar-right"
  | "arrow-left"
  | "arrow-left-outlined"
  | "arrow-right"
  | "attach"
  | "bell"
  | "benefits"
  | "blog"
  | "bookmark-filled"
  | "bookmark"
  | "calendar-add"
  | "calendar-grid"
  | "calendar"
  | "car"
  | "caret-down"
  | "caret-filled-down"
  | "caret-left"
  | "caret-left-large"
  | "caret-right"
  | "caret-up"
  | "carker-logo-bw"
  | "carker-logo-color"
  | "carker-pro"
  | "certified"
  | "certified-check-shield"
  | "calendar-change-date"
  | "chat"
  | "checkmark"
  | "checkmark-round"
  | "checkmark-rounded"
  | "circle-add"
  | "circled-envelop"
  | "circled-phone"
  | "circled-whatsapp"
  | "clients"
  | "clock"
  | "close-no-background"
  | "close-round"
  | "close"
  | "crane"
  | "csv"
  | "customer-service"
  | "dashboard-bars"
  | "delivery"
  | "digital-formats"
  | "envelop"
  | "export"
  | "facebook-rounded"
  | "filters-dashs"
  | "filters"
  | "gear"
  | "google-colored"
  | "hand-heart"
  | "hand-ok"
  | "hand-payment"
  | "hand-shake"
  | "hand-support"
  | "hand-together"
  | "hands"
  | "headset"
  | "history"
  | "home"
  | "import"
  | "information-round"
  | "landing-arrow-h-bottom"
  | "landing-arrow-h-top"
  | "landing-arrow-v-right"
  | "landing-arrow-v-left"
  | "landing-banner-icon-1"
  | "landing-banner-icon-2"
  | "landing-billing"
  | "landing-client-history"
  | "landing-efficiency"
  | "landing-postsell"
  | "landing-reputation"
  | "landing-screenshot-1"
  | "landing-screenshot-2"
  | "landing-screenshot-3"
  | "landing-screenshot-4"
  | "landing-time-management"
  | "landing-video-link"
  | "link-text"
  | "list-select"
  | "location-current"
  | "location-filled"
  | "location"
  | "maps"
  | "menu-dots"
  | "menu-hamburger"
  | "more-vertical"
  | "motorycle"
  | "order"
  | "package-icon_car_other"
  | "package-icon_moto_afinacion_frenos"
  | "package-icono-servicios-afinacion"
  | "package-icono-servicios-alineacion-balanceo"
  | "package-icono-servicios-cambio-aceite"
  | "package-icono-servicios-cambio-frenos"
  | "package-icono-servicios-paquete-vacacional"
  | "package-icono-servicios-pulido-encerado"
  | "package-icono-servicios-verificacion"
  | "package-idk"
  | "package-servicio-de-mantenimiento"
  | "panel-expand"
  | "pdf"
  | "pencil"
  | "phone"
  | "plus-add"
  | "quality"
  | "reception-hit"
  | "reception-mirror"
  | "reception-scratch"
  | "reception-uncolored"
  | "refresh"
  | "save"
  | "search"
  | "service-icon_car_other"
  | "service-icon_hojalateria_pintura"
  | "service-icon_llantas-frenos_suspension"
  | "service-icon_mecanica_general"
  | "service-icon_moto_led"
  | "service-icon_motocicletas"
  | "service-icon_sistema_electrico"
  | "service-idk"
  | "sort-arrows"
  | "tag"
  | "trash"
  | "truck"
  | "unfold"
  | "user"
  | "vehicle"
  | "whatsapp"
  | "whatsapp-colored"
  | "whatsapp-outline"
  | "wheel"
  | "workshop-car"
  | "workshop-worker-car";

export const CkAvailableIcons: CkIconNames[] = [
  "24hours",
  "add-image",
  "added-checkmark",
  "appo-tracking",
  "arrow-bar-left",
  "arrow-bar-right",
  "arrow-left",
  "arrow-left-outlined",
  "arrow-right",
  "bell",
  "benefits",
  "blog",
  "bookmark",
  "bookmark-filled",
  "calendar",
  "calendar-add",
  "calendar-change-date",
  "calendar-grid",
  "car",
  "caret-down",
  "caret-filled-down",
  "caret-left",
  "caret-left-large",
  "caret-right",
  "caret-up",
  "carker-pro",
  "certified",
  "certified-check-shield",
  "chat",
  "checkmark",
  "checkmark-round",
  "checkmark-rounded",
  "clock",
  "close-round",
  "close",
  "crane",
  "dashboard-bars",
  "delivery",
  "digital-formats",
  "envelop",
  "facebook-rounded",
  "filters",
  "filters-dashs",
  "gear",
  "google-colored",
  "hand-heart",
  "hand-ok",
  "hand-payment",
  "hand-shake",
  "hand-support",
  "hand-together",
  "hands",
  "headset",
  "history",
  "home",
  "information-round",
  "list-select",
  "location",
  "location-current",
  "location-filled",
  "maps",
  "menu-dots",
  "menu-hamburger",
  "motorycle",
  "order",
  "package-icon_car_other",
  "package-icon_moto_afinacion_frenos",
  "package-icono-servicios-afinacion",
  "package-icono-servicios-alineacion-balanceo",
  "package-icono-servicios-cambio-aceite",
  "package-icono-servicios-cambio-frenos",
  "package-icono-servicios-paquete-vacacional",
  "package-icono-servicios-pulido-encerado",
  "package-icono-servicios-verificacion",
  "package-idk",
  "package-servicio-de-mantenimiento",
  "pencil",
  "phone",
  "plus-add",
  "quality",
  "reception-hit",
  "reception-mirror",
  "reception-scratch",
  "reception-uncolored",
  "refresh",
  "save",
  "search",
  "service-icon_car_other",
  "service-icon_hojalateria_pintura",
  "service-icon_llantas-frenos_suspension",
  "service-icon_mecanica_general",
  "service-icon_moto_led",
  "service-icon_motocicletas",
  "service-icon_sistema_electrico",
  "service-idk",
  "sort-arrows",
  "trash",
  "truck",
  "user",
  "vehicle",
  "whatsapp",
  "whatsapp-colored",
  "whatsapp-outline",
  "wheel",
  "workshop-car",
  "workshop-worker-car",
];

/**
 * Interface CkIconProps
 *
 * `CkIconProps` extends `CustomIconComponentProps` from the antd icons components.
 * It defines props that are accepted by `CkIcon` component —specifically
 * a `name` prop of type `CkIconNames` that should match one of the available icon names defined in `CkIconNames`.
 *
 * @property {CkIconNames} name - Icon name that must be one of string literal types defined in `CkIconNames`.
 */
export interface CkIconProps extends CustomIconComponentProps {
  /**
   * Icon name that must be one of string literal types defined in `CkIconNames`.
   */
  name: CkIconNames;
}

/**
 * Extends AntD Custom Icon props
 * See full doc on https://4x.ant.design/components/icon/#Custom-Icon
 */
const CkIcon: FC<CkIconProps> = ({ name, ...rest }) => {
  try {
    const svg = require(`./${name}`).default;
    return (
      <Icon className={`ck-icon ${name}`} component={svg} {...(rest || {})} />
    );
  } catch (e) {
    return null;
  }
};

export default CkIcon;
