import React from "react";

const Home = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
  >
    <g clipPath="url(#clip0_875_10152)">
      <path
        d="M23.54 25.1921H0.5V10.8454L12.02 0.733398L23.54 10.8454V25.1921Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1401 15.5605H8.89014V25.1925H15.1401V15.5605Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_875_10152">
        <rect
          width="24.04"
          height="25.5253"
          fill="none"
          transform="translate(0 0.200195)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Home;
