import React from "react";

const Import = () => (
  <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M11.9995 2.49951H6.02211C5.6369 2.49951 5.32568 2.78782 5.32568 3.14467V13.1447C5.32568 13.5015 5.6369 13.7898 6.02211 13.7898H14.2922V16.0479H10.5489C10.3574 16.0479 10.2007 16.1931 10.2007 16.3705V17.3382C10.2007 17.4269 10.279 17.4995 10.3748 17.4995H19.7766C19.8723 17.4995 19.9507 17.4269 19.9507 17.3382V16.3705C19.9507 16.1931 19.794 16.0479 19.6025 16.0479H15.8592V13.7898H24.1293C24.5145 13.7898 24.8257 13.5015 24.8257 13.1447V3.14467C24.8257 2.78782 24.5145 2.49951 24.1293 2.49951H17.4995V3.95112H23.2587V12.3382H6.89265V3.95112H11.9995V2.49951Z"
          fill="#2369BD"/>
    <path
      d="M14.8945 8.94617C14.907 8.96324 14.9229 8.97705 14.9411 8.98655C14.9593 8.99604 14.9793 9.00098 14.9995 9.00098C15.0197 9.00098 15.0397 8.99604 15.0579 8.98655C15.0761 8.97705 15.092 8.96324 15.1045 8.94617L16.9709 6.41616C17.0392 6.32332 16.9776 6.18584 16.8659 6.18584L15.6311 6.18584L15.6311 0.143814C15.6311 0.0652534 15.5711 0.000976563 15.4978 0.000976563L14.4979 0.000976563C14.4246 0.000976563 14.3646 0.0652534 14.3646 0.143814L14.3646 6.18405H13.1331C13.0214 6.18405 12.9598 6.32153 13.0281 6.41438L14.8945 8.94617Z"
      fill="#2369BD"/>
  </svg>

);

export default Import;
